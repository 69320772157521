import React, { useState } from "react";
import { StyleSheet, View, Text, Image, Pressable, TextInput } from 'react-native';
import backIcon from '../../Assets/Images/backArrow.png';
import backBlackIcon from '../../Assets/Images/back-arrow-black.png';
import { useTheme } from "../../Contexts/ThemeProvider";
import blackBuld from '../../Assets/Images/bulb-black.png';
import whiteBuld from '../../Assets/Images/bulb-white.png';
import moonBlack from '../../Assets/Images/moon-black.png';
import moonWhite from '../../Assets/Images/moon-white.png';

const ThemeScreen = (props) => {
    const { dark, setScheme, colors } = useTheme();
    const _onBack = () => props.navigation.goBack()

    return (
        <>
            <View style={[styles.container, { backgroundColor: colors.Primary }]}>
                <View style={[styles.header, { borderColor: colors.Secondary }]}>
                    <View>
                        <Pressable onPress={() => _onBack()}>
                            <Image source={dark ? backIcon : backBlackIcon} />
                        </Pressable>
                    </View>
                    <View style={styles.titleView}>
                        <Text style={[styles.headerTitle, { color: colors.Secondary }]}>Themes</Text>
                    </View>
                </View>
                <View>
                    <Pressable style={[styles.item, { borderColor: colors.Secondary }]}>
                        <View style={{ flexDirection: 'row' }}>
                            <Image source={dark ? whiteBuld : blackBuld} />
                            <Text style={[styles.name, { color: colors.Secondary }]}>Light</Text>
                        </View>
                        <Pressable onPress={() => setScheme('light')} style={{ flexDirection: 'row' }}>
                            <View style={[styles.radioButtonOuter, { borderColor: colors.Secondary, }]}>
                                {
                                    !dark ?
                                        <View style={[styles.radioButtonInner, { backgroundColor: colors.Secondary }]} />
                                        : null
                                }
                            </View>
                        </Pressable>
                    </Pressable>
                    <Pressable style={[styles.item, { borderColor: colors.Secondary }]}>
                        <View style={{ flexDirection: 'row' }}>
                            <Image source={dark ? moonWhite : moonBlack} style={{ height: 24, width: 24 }} />
                            <Text style={[styles.name, { color: colors.Secondary }]}>Dark</Text>
                        </View>
                        <Pressable onPress={() => setScheme('dark')} style={{ flexDirection: 'row' }}>
                            <View style={[styles.radioButtonOuter, { borderColor: colors.Secondary, }]}>
                                {
                                    dark ?
                                        <View style={[styles.radioButtonInner, { backgroundColor: colors.Secondary }]} />
                                        : null
                                }
                            </View>
                        </Pressable>
                    </Pressable>
                </View>
            </View>
        </>
    )
}

export default ThemeScreen;

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    header: {
        paddingLeft: 25,
        marginTop: 10,
        borderBottomWidth: 1,
        flexDirection: 'row',
        paddingRight: 25,
        height: 40
    },
    headerTitle: {
        fontSize: 16,
    },
    titleView: {
        alignItems: 'center',
        width: '90%'
    },
    item: {
        flexDirection: 'row',
        padding: 12,
        borderBottomWidth: 1,
        justifyContent: 'space-between'
    },
    name: {
        fontSize: 16,
        marginLeft: 20
    },
    radioButtonOuter: {
        height: 24,
        width: 24,
        borderRadius: 12,
        borderWidth: 2,
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 20
    },
    radioButtonInner: {
        height: 12,
        width: 12,
        borderRadius: 6,
    }
})