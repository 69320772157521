import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Pressable, Text, ActivityIndicator, FlatList, TextInput, Modal } from 'react-native';
import { Primary, Secondary, Width, color1 } from '../../Utility/Utility';
import MenuWhiteIcon from '../../Assets/Images/menu-white.png';
import MenuBlackIcon from '../../Assets/Images/menu-black.png';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useTheme } from '../../Contexts/ThemeProvider';
import filterBlack from '../../Assets/Images/filterBlack.png';
import filterWhite from '../../Assets/Images/filterWhite.png';
import SearchIcon from '../../Assets/Images/search-icon-white.png';
import SearchBlackIcon from '../../Assets/Images/searchBlue.png';
import crossIcon from '../../Assets/Images/cross-black.png';
import { LinearGradient } from 'expo-linear-gradient';
import moment from 'moment';
import { useIsFocused } from "@react-navigation/native";
import { DrawerActions } from '@react-navigation/native';
import filterBlueIcon from '../../Assets/Images/filterBlue.png';
import { Image } from 'expo-image';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RefreshControl } from 'react-native-web-refresh-control'
import { resetAuth } from '../../Actions/AuthActions';
import { useDispatch } from 'react-redux';

const Trades = (props) => {
    const dispatch = useDispatch()
    const { dark, colors } = useTheme();
    const [selectedSegment, setSelectedSegment] = useState('NFO')
    const [allTrades, setAllTrades] = useState([])
    const userId = useRef('')
    const [isLoading, setLoading] = useState(false)
    const [showSearch, setShowSearch] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [modalVisible, setModalVisible] = useState(false);
    const [date, setDate] = useState(new Date())
    const [enddate, setEndDate] = useState(new Date())
    const [open, setOpen] = useState(false)
    const [dateType, setDateType] = useState('')
    const isFocused = useIsFocused();


    const _onMenuPress = () => {
        props.navigation.dispatch(DrawerActions.openDrawer());
    }


    useEffect(() => {
        getData()
    }, [isFocused])

    const getData = async () => {
        try {
            const jsonValue = await AsyncStorage.getItem('user_detail')
            const data = jsonValue != null ? JSON.parse(jsonValue) : null;
            userId.current = data.id
            checkTab()
        } catch (e) {
            // error reading value
        }
    }

    const checkTab = async () => {
        await AsyncStorage.getItem('tab')
            .then(req => JSON.parse(req))
            .then(data => {
                if (data != null) {
                    setSelectedSegment(data.currenTab)
                    getTrades(data.currenTab)
                } else {
                    getTrades('NFO')
                }
            })
            .catch(error => {
                getTrades('NFO')
            });
    }

    const getTrades = (value, search, fromDate, toDate) => {
        var fDate = fromDate ? moment(fromDate).format('YYYY-MM-DD') : ''
        var tDate = toDate ? moment(toDate).format('YYYY-MM-DD') : ''
        setLoading(true)
        if (fDate) {
            var url = `${BASE_URL}/trade-list?exchange=${value}&trade_status=EXECUTED&user_id=${userId.current}&keyword=${search ? search : ''}&from_date=${fDate}&to_date=${tDate}&sort_by=trade_date&order_by=DESC`
        } else {
            var url = `${BASE_URL}/trade-list?exchange=${value}&trade_status=EXECUTED&user_id=${userId.current}&day=${fDate ? '' : 'TODAY'}&keyword=${search ? search : ''}&from_date=${fDate}&to_date=${tDate}&sort_by=trade_date&order_by=DESC`
        }
        axios.get(url)
            .then((res) => {
                setAllTrades(res.data)
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    const onChangeTab = async (value) => {
        const data = JSON.stringify({ currenTab: value })
        await AsyncStorage.setItem('tab', data)
        setAllTrades([])
        setSelectedSegment(value)
        getTrades(value)
    }

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <button style={{ color: colors.Secondary, backgroundColor: '#f27968', borderRadius: 12, height: 45, width: Width * 0.9, }} onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    return (
        <>
            <View style={[styles.container, { backgroundColor: colors.Primary }]}>
                <View style={[styles.header, { borderColor: colors.Secondary }]}>
                    <View style={{ flexDirection: 'row' }}>
                        <Pressable onPress={() => _onMenuPress()}>
                            <Image source={dark ? MenuWhiteIcon : MenuBlackIcon} style={{ height: 25, width: 25 }} />
                        </Pressable>
                        <Text style={[styles.orderText, { color: colors.Secondary, }]}>Trades</Text>
                    </View>
                    <View>
                        {
                            showSearch == false &&
                            <View style={styles.headerIcon}>
                                <Pressable onPress={() => setModalVisible(true)}>
                                    <Image source={dark ? filterBlueIcon : filterBlueIcon} style={styles.filterIcon} />
                                </Pressable>
                                <Pressable onPress={() => setShowSearch(true)}>
                                    <Image source={dark ? SearchIcon : SearchBlackIcon} style={styles.searchIcon} />
                                </Pressable>
                            </View>
                        }
                        {
                            showSearch == true &&
                            <View style={styles.headerIcon}>
                                <TextInput style={[styles.input, { color: colors.Secondary, backgroundColor: colors.color2 }]} placeholder='Search' placeholderTextColor={'#D3D3D3'} onChangeText={text => { setSearchText(text), getTrades(selectedSegment, text) }} value={searchText} />
                                <Pressable onPress={() => {
                                    setShowSearch(false)
                                    getTrades(selectedSegment)
                                }}>
                                    <Image source={crossIcon} style={styles.crossLogo} />
                                </Pressable>
                            </View>
                        }
                    </View>
                </View>
                <View style={{ backgroundColor: '#f27968', paddingBottom: 10 }}>
                    <View style={styles.segmentTabView}>
                        <Pressable style={[styles.segementTab, { backgroundColor: selectedSegment == 'NFO' ? '#f55943' : colors.Primary, borderColor: colors.Secondary }]} onPress={() => { onChangeTab('NFO') }}>
                            <Text style={[styles.tabText, { color: selectedSegment == 'NFO' ? colors.Primary : colors.Secondary }]}>NSE</Text>
                        </Pressable>
                        <Pressable style={[styles.segementTab, { backgroundColor: selectedSegment == 'MCX' ? '#f55943' : colors.Primary, borderColor: colors.Secondary }]} onPress={() => { onChangeTab('MCX') }}>
                            <Text style={[styles.tabText, { color: selectedSegment == 'MCX' ? colors.Primary : colors.Secondary }]}>MCX</Text>
                        </Pressable>
                    </View>
                </View>
                {isLoading && allTrades.length == 0 && <View style={styles.loaderView}>
                    <ActivityIndicator size="small" color='#000' />
                </View>}
                {!isLoading && allTrades.length == 0 && <View style={styles.loaderView}>
                    <Text style={{ color: colors.Secondary }}>No Scripts Found!</Text>
                </View>}
                {!isLoading && allTrades.length > 0 &&
                    <FlatList
                        data={allTrades}
                        renderItem={({ item }) => <View style={[styles.item, { backgroundColor: item.order_action == 'SELL' ? "#edd3d3" : '#d9f2d9' }]} key={new Date()}>
                            <View style={styles.nameView}>
                                <Text style={[styles.tradeName, { color: colors.Secondary }]}>{item.trading_symbol}</Text>
                                <Text style={styles.price}>{item.trade_date && moment(item.trade_date).format('DD/MM/YYYY HH:mm:ss')}</Text>

                            </View>
                            <View style={styles.priceView}>
                                <Text style={[styles.tradeQty, { color: item.order_action == 'SELL' ? '#FF033E' : '#1034A6' }]}>{item.order_type != 'INTRADAY' && item.order_action}
                                    {item.order_type == 'LIMIT' && <Text style={{ fontSize: 12, marginLeft: 2, marginBottom: -8 }}>Limit</Text>}
                                    {item.order_type == 'STOPLOSS' && <Text style={{ fontSize: 12, marginLeft: 2 }}>StopLoss</Text>}
                                    {item.order_type == 'INTRADAY' && <Text style={{ fontSize: 12, marginLeft: 2 }}>Intraday Close Limit</Text>}
                                </Text>
                                {item.order_type != 'INTRADAY' && <Text style={{ color: colors.Secondary, fontSize: 16, fontWeight: '600' }}>Price : {item.price && (item.price.toFixed(2))}</Text>}
                                {item.order_type != 'INTRADAY' && <Text style={{ color: colors.Secondary, fontSize: 16, fontWeight: '600' }}>Qty: {item.quantity} ({item.lot && item.lot.toFixed(2)})</Text>}
                                {/* <Text style={{ color: colors.Secondary }}></Text> */}
                            </View>
                        </View>}
                        keyExtractor={item => item.id}
                        refreshControl={
                            <RefreshControl refreshing={isLoading} onRefresh={() => getTrades(selectedSegment)} />
                        }
                    />
                }
            </View>
            <Modal animationType="slide" transparent={true} visible={modalVisible}>
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <View style={{}}>
                            <Text style={{ color: colors.Secondary, fontSize: 18, fontWeight: 'bold', marginTop: 10 }}>Filter</Text>
                            <Text style={styles.label}>From Date</Text>
                            <DatePicker selected={date} onChange={(date) => setDate(date)} customInput={<ExampleCustomInput />} />
                            <Text style={styles.label}>To Date</Text>
                            <DatePicker selected={enddate} onChange={(date) => setEndDate(date)} customInput={<ExampleCustomInput />} />
                            <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                <Pressable style={styles.cancelButton} onPress={() => {
                                    setDate(new Date())
                                    setEndDate(new Date())
                                    setModalVisible(!modalVisible)
                                    getTrades(selectedSegment, searchText, '', '')
                                }}>
                                    <Text style={styles.cancelText}>Reset</Text>
                                </Pressable>
                                <Pressable style={styles.cancelButton} onPress={() => setModalVisible(!modalVisible)}>
                                    <Text style={styles.cancelText}>Cancel</Text>
                                </Pressable>
                                <Pressable onPress={() => { setModalVisible(false), getTrades(selectedSegment, searchText, date, enddate) }}>
                                    <LinearGradient start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} colors={['#26D0CE', '#1A2980']} style={styles.continueButton}>
                                        <Text style={styles.continueText}>Continue</Text>
                                    </LinearGradient>
                                </Pressable>
                            </View>
                        </View>
                    </View>
                </View>
            </Modal>
        </>
    )
}

export default Trades;

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    header: {
        paddingLeft: 25,
        marginTop: 10,
        borderBottomWidth: 1,
        flexDirection: 'row',
        paddingRight: 25,
        height: 40,
        justifyContent: 'space-between'
    },
    orderText: {
        marginLeft: 20,
        fontSize: 18,
        fontWeight: '500'
    },
    dropdownView: {
        marginTop: 20,
        marginLeft: 22,
        marginRight: 22,
    },
    segmentTabView: {
        flexDirection: 'row',
        marginLeft: 22,
        marginRight: 22,
        marginTop: 10,
    },
    segementTab: {
        width: '50%',
        alignItems: 'center',
        borderWidth: 1,
        padding: 3,
        borderRadius: 5,
        marginLeft: 5
    },
    item: {
        // marginLeft: 22,
        // marginRight: 22,
        marginTop: 1,
        borderRadius: 5,
        padding: 10
    },
    tradeName: {
        fontSize: 16,
        fontWeight: '700'
    },
    tradeQty: {
        color: 'green',
        fontSize: 18
    },
    nameView: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    priceView: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 5
    },
    price: {
        color: '#5A5A5A',
    },
    loaderView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    filterIcon: {
        height: 25,
        width: 25,
        marginTop: 3,
        marginRight: 10
    },
    headerIcon: {
        flexDirection: 'row'
    },
    input: {
        borderRadius: 12,
        width: Width * 0.5,
        paddingLeft: 15,
        bottom: 6
    },
    dateInput: {
        borderRadius: 12,
        width: Width * 0.9,
        height: 45,
        paddingLeft: 15,
        bottom: 6,
        marginTop: 10,
        justifyContent: 'center'
    },
    label: {
        color: color1,
        fontSize: 15,
        fontFamily: 'Roboto-Regular',
        fontWeight: '600',
        marginTop: 10
    },
    cancelButton: {
        borderRadius: 5,
        marginTop: 18,
        marginLeft: 10,
        borderWidth: 1
    },
    cancelText: {
        fontSize: 16,
        textAlign: 'center',
        padding: 10,
        backgroundColor: 'transparent',
        marginLeft: 15,
        marginRight: 15
    },
    continueButton: {
        borderRadius: 5,
        marginTop: 18,
        marginLeft: 10,
    },
    continueText: {
        fontSize: 16,
        textAlign: 'center',
        color: 'white',
        padding: 10,
        backgroundColor: 'transparent',
        marginLeft: 15,
        marginRight: 15
    },
    searchIcon: {
        // bottom: 5
        height: 32,
        width: 32
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
    },
    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 10,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },

})