import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import persist from './src/Config/Store';
import Routes from './src/Navigation';
import { PersistGate } from 'redux-persist/integration/react';
import Toast, { ErrorToast, SuccessToast } from 'react-native-toast-message';

const App = () => {
  const persistStore = persist();

  const toastConfig = {
    success: (props) => (
      <SuccessToast
        {...props}
        text2Style={{
          fontSize: 13,
          color: 'black',
          fontWeight: 'bold'
        }}
      />

    ),
    error: (props) => (
      <ErrorToast
        {...props}
        contentContainerStyle={{ backgroundColor: 'red' }}
        text1Style={{
          color: 'white'
        }}
        text2Style={{
          fontSize: 13,
          color: 'white',
          fontWeight: 'bold'
        }}
      />
    ),
  }

  return (
    <>
      <Provider store={persistStore.store}>
        <PersistGate loading={null} persistor={persistStore.persistor}>
          <Routes />
        </PersistGate>
      </Provider>
      <Toast config={toastConfig} />
    </>
  )
}

export default App;