import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, Image, Pressable, ScrollView, FlatList, ActivityIndicator } from 'react-native';
import { getCustomDate, Primary, Secondary } from "../../Utility/Utility";
import backIcon from '../../Assets/Images/backArrow.png';
import backBlackIcon from '../../Assets/Images/back-arrow-black.png';
import axios from "axios";
import { BASE_URL } from "../../Config";
import { useTheme } from "../../Contexts/ThemeProvider";
import moment from "moment";
import { resetAuth } from "../../Actions/AuthActions";
import { useDispatch } from "react-redux";

const PositionTrades = ({ props, route, navigation }) => {
    const dispatch = useDispatch()
    const { token } = route.params;
    const [isLoading, setLoading] = useState(false)
    const _onBack = () => navigation.goBack()
    const [trades, setTrades] = useState([])
    const { dark, colors } = useTheme();

    useEffect(() => {
        getTrades()
    }, [])

    const getTrades = () => {
        setLoading(true)
        axios.get(`${BASE_URL}/trade-list?instrument_token=${token}&created_date=DESC&trade_status=EXECUTED`)
            .then((res) => {
                setTrades(res.data)
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    return (
        <>
            <View style={[styles.container, { backgroundColor: colors.Primary }]}>
                <View style={[styles.header, { borderColor: colors.Secondary }]}>
                    <View>
                        <Pressable onPress={() => _onBack()}>
                            <Image source={dark ? backIcon : backBlackIcon} style={{ height: 18, width: 18 }} />
                        </Pressable>
                    </View>
                    <View style={styles.titleView}>
                        <Text style={[styles.headerTitle, { color: colors.Secondary }]}>Trades</Text>
                    </View>
                </View>
                {isLoading && trades.length == 0 && <View style={styles.loaderView}>
                    <ActivityIndicator size="small" color="#000" />
                </View>}
                {!isLoading && trades.length == 0 && <View style={styles.loaderView}>
                    <Text style={{ color: 'white' }}>No Trades Found!</Text>
                </View>}
                {
                    !isLoading && trades.length > 0 &&
                    <FlatList
                        data={trades}
                        renderItem={({ item }) => <Pressable style={[styles.item, { borderColor: colors.Secondary, backgroundColor: item.order_action == 'SELL' ? '#edd3d3' : item.order_action == 'BUY' ? '#d9f2d9' : '' }]} key={new Date()}>
                            <View style={styles.nameView}>
                                <Text style={[styles.tradeName, { color: colors.Secondary }]}>{item.trading_symbol}</Text>
                                <Text style={styles.tradeQty}>{item.trade_date && moment(item.trade_date).format('DD/MM/YYYY HH:mm:ss')}</Text>
                            </View>
                            <View style={styles.priceView}>
                                <Text style={[styles.tradeQty, { color: item.order_action == 'SELL' ? '#FF033E' : '#1034A6' }]}>{item.order_action}</Text>
                                <Text style={{ color: colors.Secondary, fontSize: 16, fontWeight: '600' }}>Price : {item.price && (item.price.toFixed(2))}</Text>
                                <Text style={{ color: colors.Secondary, fontSize: 16, fontWeight: '600' }}>Qty: {item.quantity} ({item.lot && item.lot.toFixed(2)})</Text>
                            </View>
                        </Pressable>}
                        keyExtractor={item => item.id}
                    />
                }
            </View>
        </>
    )
}

export default PositionTrades;

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    header: {
        paddingLeft: 25,
        marginTop: 10,
        borderBottomWidth: 1,
        flexDirection: 'row',
        paddingRight: 25,
        height: 40
    },
    headerTitle: {
        fontSize: 16,
    },
    titleView: {
        alignItems: 'center',
        width: '90%'
    },
    item: {
        borderBottomWidth: 1,
        // marginLeft: 22,
        // marginRight: 22,
        // marginTop: 12,
        borderRadius: 5,
        padding: 10
    },
    tradeName: {
        color: Secondary,
        fontSize: 18,
        fontWeight: '700'
    },
    tradeQty: {
        color: '#5A5A5A',
    },
    nameView: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    priceView: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 5
    },
    avgPrice: {
        color: Secondary,
        fontSize: 12
    },
    rate: {
        color: Secondary,
        fontSize: 10
    },
    price: {
        color: 'green',
    },
    loaderView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    }
})