import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Text, Image, Pressable, TextInput, FlatList, ScrollView, ActivityIndicator } from 'react-native';
import { useTheme } from '../../Contexts/ThemeProvider';
import backIcon from '../../Assets/Images/backArrow.png';
import backBlackIcon from '../../Assets/Images/back-arrow-black.png';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import moment from 'moment';
import { Width, color1 } from '../../Utility/Utility';
import { useIsFocused } from "@react-navigation/native";
import { RefreshControl } from 'react-native-web-refresh-control'
import { resetAuth } from '../../Actions/AuthActions';
import { useDispatch } from 'react-redux';

const LedgerReport = (props) => {
    const dispatch = useDispatch()
    const { dark, colors } = useTheme();
    const _onBack = () => props.navigation.goBack()
    const userId = useRef('')
    const [reportData, setReportData] = useState([])
    const [credit, setCredit] = useState(0)
    const [debit, setDebit] = useState(0)
    const isFocused = useIsFocused();
    const [isLoading, setLoading] = useState(true)

    useEffect(() => {
        getUserData()

    }, [isFocused])

    const getUserData = () => {
        setLoading(true)
        axios.get(`${BASE_URL}/me`)
            .then((res) => {
                userId.current = res.data.id
                getReport(res?.data?.id)
            })
            .catch((e) => {
                setLoading(false)
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    const getReport = (id) => {
        axios.get(`${BASE_URL}/user-settlement-list-app?id=${userId.current}`)
            .then((res) => {
                var credit = 0;
                var debit = 0;
                if (res.data && res.data.length > 0) {
                    for (var i in res.data) {
                        if (i == 0) {
                            res.data[i].balance = 0;
                        } else {
                            res.data[i].balance = res.data[i - 1].balance;
                        }

                        res.data[i].balance += res.data[i].debit ? res.data[i].debit : 0;
                        debit += res.data[i].debit ? res.data[i].debit : 0;
                        credit += res.data[i].credit ? res.data[i].credit : 0;
                        res.data[i].balance += res.data[i].credit
                            ? res.data[i].credit
                            : 0;
                    }
                }
                setDebit(debit)
                setCredit(credit)
                setReportData(res.data)
                setLoading(false)
            })
            .catch((e) => {
                console.log(e)
                setLoading(false)
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }



    return (
        <>
            <View style={[styles.container, { backgroundColor: colors.Primary }]}>
                <View style={[styles.header, { borderColor: colors.Secondary }]}>
                    <View>
                        <Pressable onPress={() => _onBack()} style={{ height: 35, width: 40 }}>
                            <Image source={dark ? backIcon : backBlackIcon} style={{ marginTop: 5, height: 18, width: 18 }} />
                        </Pressable>
                    </View>
                    <View style={styles.titleView}>
                        <Text style={[styles.headerTitle, { color: colors.Secondary }]}>Ledger Report</Text>
                    </View>
                </View>
                <View>
                    <View elevation={5} style={{
                        shadowColor: (Number(debit) + Number(credit)) > 0 ? 'green' : 'red', backgroundColor: 'white',
                        borderRadius: 10,
                        width: '80%',
                        justifyContent: 'center',
                        alignSelf: 'center',
                        alignItems: 'center',
                        marginTop: 20,
                        marginBottom: 10,
                        padding: 10,
                        shadowOffset: {
                            width: 50,
                            height: 30
                        },
                        shadowRadius: 500,
                        shadowOpacity: 50.0,
                        elevation: 50
                    }}>
                        <Text style={{ color: (Number(debit) + Number(credit)) > 0 ? 'green' : 'red', fontSize: 22, fontWeight: 'bold' }}>{(Number(credit) + Number(debit)).toFixed(2)}</Text>
                    </View>
                </View>
                {!isLoading && reportData.length > 0 &&
                    <FlatList
                        data={reportData}
                        renderItem={({ item, index }) => <View style={[styles.item, { borderBottomWidth: 1, borderColor: '#D3D3D3', backgroundColor: item.net_qty < 0 ? '#edd3d3' : item.net_qty > 0 ? '#d9f2d9' : item.net_qty == 0 ? '#fff' : '' }]} key={new Date()}>
                            <Pressable onPress={() => props.navigation.navigate('LedgerDetail', { data: item })} style={{ width: '70%' }}>
                                <View style={styles.nameView}>
                                    <Text style={[styles.tradeName, { color: colors.Secondary, fontSize: 15 }]}>{item.remark} MCX-NSE</Text>

                                </View>
                                <View style={styles.priceView}>
                                    <Text style={[styles.avgPrice, { color: colors.Secondary, fontSize: 12, marginTop: 2 }]}>{item.created_at && moment(item.created_at).format('DD-MM-YYYY')}</Text>
                                </View>
                            </Pressable>
                            <View style={{ width: '30%', justifyContent: 'flex-end', flexDirection: 'row' }}>
                                <Text style={[styles.tradeName, { color: Number(item.debit) < 0 ? 'red' : Number(item.credit) > 0 ? 'green' : 'black', fontWeight: 'bold', fontSize: 20 }]}>{item.debit != 0 ? item.debit.toFixed(2) : item.credit.toFixed(2)}</Text>
                            </View>
                        </View>}
                        keyExtractor={item => item.id}
                        refreshControl={
                            <RefreshControl refreshing={isLoading} onRefresh={() => { getUserData() }} />
                        }
                    />
                }
                {
                    !isLoading && reportData?.length == 0 &&
                    <View style={{ justifyContent: 'center', alignItems: 'center', alignSelf: 'center', flex: 1 }}>
                        <Text style={{ color: colors.Secondary }}>No Data Found!</Text>
                    </View>
                }
                {isLoading && reportData.length == 0 && <View style={styles.loaderView}>
                    <ActivityIndicator size="small" color='#000' />
                </View>}
            </View>
        </>
    )
}

export default LedgerReport;

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    header: {
        paddingLeft: 25,
        marginTop: 10,
        borderBottomWidth: 1,
        flexDirection: 'row',
        paddingRight: 25,
        height: 40
    },
    titleView: {
        alignItems: 'center',
        width: '80%',
    },
    headerTitle: {
        fontSize: 16,
    },
    verticalLine: {
        borderRightWidth: 1
    },
    filterIcon: {
        height: 25,
        width: 25,
        marginTop: 3,
        marginRight: 10
    },
    headerIcon: {
        flexDirection: 'row'
    },
    dateInput: {
        borderRadius: 12,
        width: Width * 0.9,
        height: 45,
        paddingLeft: 15,
        bottom: 6,
        marginTop: 10,
        justifyContent: 'center'
    },
    label: {
        color: color1,
        fontSize: 15,
        fontFamily: 'Roboto-Regular',
        fontWeight: '600',
        marginTop: 10
    },
    cancelButton: {
        borderRadius: 5,
        marginTop: 18,
        marginLeft: 10,
        borderWidth: 1
    },
    cancelText: {
        fontSize: 16,
        textAlign: 'center',
        padding: 10,
        backgroundColor: 'transparent',
        marginLeft: 15,
        marginRight: 15
    },
    continueButton: {
        borderRadius: 5,
        marginTop: 18,
        marginLeft: 10,
    },
    continueText: {
        fontSize: 16,
        textAlign: 'center',
        color: 'white',
        padding: 10,
        backgroundColor: 'transparent',
        marginLeft: 15,
        marginRight: 15
    },
    segmentTabView: {
        flexDirection: 'row',
        marginLeft: 22,
        marginRight: 22,
        marginTop: 10
    },
    segementTab: {
        width: '50%',
        alignItems: 'center',
        borderWidth: 1,
        padding: 3,
        borderRadius: 5,
        marginLeft: 5
    },
    loaderView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 50
    },
    item: {
        marginTop: 1,
        borderRadius: 5,
        padding: 10,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    tradeName: {
        fontSize: 16,
        fontWeight: '700'
    },
    nameView: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    textStyle: {
        color: '#FFFFFF'
    },
    buttonContainer: {

    }
})