import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Text, Image, Pressable, FlatList, ScrollView, ActivityIndicator, Linking } from 'react-native';
import { useTheme } from '../../Contexts/ThemeProvider';
import { Width, color1 } from '../../Utility/Utility';
import backIcon from '../../Assets/Images/backArrow.png';
import backBlackIcon from '../../Assets/Images/back-arrow-black.png';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import moment from 'moment';
import pdfIcon from '../../Assets/Images/pdfIcon.png';
import { resetAuth } from '../../Actions/AuthActions';
import { useDispatch } from 'react-redux';

const LedgerDetail = ({ navigation, route, props }) => {
    const dispatch = useDispatch()
    const { dark, colors } = useTheme();
    const { data } = route.params
    const [reportData, setReportData] = useState([])
    const _onBack = () => navigation.goBack()
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        getDetail()
    }, [])

    const getDetail = () => {
        setLoading(true)
        axios.get(`${BASE_URL}/user-settle-trades-list?id=${data.id}`)
            .then((res) => {
                console.log("Res", res.data)
                res.list = res.data.list
                var tempList = []
                for (var i in res.list) {
                    res.list[i].datatype = 'DATA'
                    var k = -1;
                    var net_price = Math.abs((res.list[i].net_price * res.list[i].quantity) - (res.list[i].price * res.list[i].quantity))
                    for (var j in tempList) {
                        if (tempList[j].trading_symbol == res.list[i].trading_symbol) {
                            k = j;
                        }
                    }

                    var netamount = (res.list[i].net_price * res.list[i].quantity);
                    if (res.list[i].order_action == "BUY") {
                        netamount = -netamount;
                    }
                    if (k == -1) {
                        var temp = {
                            trading_symbol: res.list[i].trading_symbol, list: [res.list[i]], bq: res.list[i].order_action == "BUY" ? res.list[i].quantity : 0, sq: res.list[i].order_action == "SELL" ? res.list[i].quantity : 0, price: res.list[i].order_action == "BUY" ? -res.list[i].price * res.list[i].quantity : res.list[i].price * res.list[i].quantity, net_price: net_price, netamount: netamount
                        };
                        tempList.push(temp);
                    } else {
                        tempList[k].bq += res.list[i].order_action == "BUY" ? res.list[i].quantity : 0;
                        tempList[k].sq += res.list[i].order_action == "SELL" ? res.list[i].quantity : 0;
                        if (res.list[i].order_action == "SELL") {
                            tempList[k].price += res.list[i].price * res.list[i].quantity;
                        } else {
                            tempList[k].price -= res.list[i].price * res.list[i].quantity;
                        }
                        tempList[k].net_price += net_price;
                        tempList[k].netamount += netamount;
                        tempList[k].list.push(res.list[i]);
                    }

                }
                console.log("temo", tempList)

                for (var m in tempList) {

                    var obj = {
                        datatype: 'Total',
                        title: 'Script Wise total:',
                        bq: tempList[m].bq,
                        sq: tempList[m].sq,
                        price: tempList[m].price,
                        net_price: tempList[m].net_price,
                        netamount: tempList[m].netamount,
                    }

                    tempList[m].list.push(obj);

                }
                setReportData(tempList)
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    const onDownload = () => {
        axios.get(`${BASE_URL}/get-url`)
            .then((res) => {
                Linking.openURL(`${res.data}/${data.id}`);
            })
            .catch((e) => {
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })

    }

    return (
        <>
            <View style={[styles.container, { backgroundColor: colors.Primary }]}>
                <View style={[styles.header, { borderColor: colors.Secondary }]}>
                    <View>
                        <Pressable onPress={() => _onBack()}>
                            <Image source={dark ? backIcon : backBlackIcon} style={{ marginTop: 5, height: 18, width: 18 }} />
                        </Pressable>
                    </View>
                    <View style={styles.titleView}>
                        <Text style={[styles.headerTitle, { color: colors.Secondary }]}>Ledger Report Detail</Text>
                    </View>
                    <View>
                        <Pressable onPress={() => onDownload()} style={{ marginLeft: 10, marginTop: 5 }}>
                            <Image source={pdfIcon} style={{ height: 25, width: 25 }} />
                        </Pressable>
                    </View>
                </View>
                <View style={{ justifyContent: 'center', alignItems: 'center', marginTop: 12 }}>
                    <Text style={{ fontSize: 18, fontWeight: '700' }}>{data.remark}</Text>
                </View>
                <ScrollView style={{ marginTop: 10, marginLeft: 5, marginRight: 10, padding: 5, marginBottom: 20 }}>
                    <View style={{ marginBottom: 20 }}>
                        {
                            reportData.map((res) => {
                                return (
                                    <>
                                        <View style={{ justifyContent: 'center', alignItems: 'center' }} key={new Date()}>
                                            <Text style={{ fontWeight: '700', marginBottom: 10, marginTop: 10 }}>{res.trading_symbol}</Text>
                                        </View>
                                        {/* TABLE HEADER */}
                                        <View style={{ width: '100%', flexDirection: 'row', borderWidth: 1, borderColor: colors.Secondary }}>
                                            <Text style={{ fontSize: 10, fontWeight: '900', width: '18%', textAlign: 'center', paddingTop: 10, paddingBottom: 10, color: colors.Secondary, borderColor: colors.Secondary, borderRightWidth: 1 }}>Date Time</Text>
                                            <Text style={{ fontSize: 10, fontWeight: '900', width: '10%', textAlign: 'center', paddingTop: 10, paddingBottom: 10, color: colors.Secondary, borderColor: colors.Secondary, borderRightWidth: 1 }}>Type</Text>
                                            <Text style={{ fontSize: 10, fontWeight: '900', width: '10%', textAlign: 'center', paddingTop: 10, paddingBottom: 10, color: colors.Secondary, borderColor: colors.Secondary, borderRightWidth: 1 }}>Lot</Text>
                                            <Text style={{ fontSize: 10, fontWeight: '900', width: '9%', textAlign: 'center', paddingTop: 10, paddingBottom: 10, color: colors.Secondary, borderColor: colors.Secondary, borderRightWidth: 1 }}>Buy Qty</Text>
                                            <Text style={{ fontSize: 10, fontWeight: '900', width: '8%', textAlign: 'center', paddingTop: 10, paddingBottom: 10, color: colors.Secondary, borderColor: colors.Secondary, borderRightWidth: 1 }}>Sell Qty</Text>
                                            <Text style={{ fontSize: 10, fontWeight: '900', width: '15%', textAlign: 'center', paddingTop: 10, paddingBottom: 10, color: colors.Secondary, borderColor: colors.Secondary, borderRightWidth: 1 }}>Rate</Text>
                                            <Text style={{ fontSize: 10, fontWeight: '900', width: '18%', textAlign: 'center', paddingTop: 10, paddingBottom: 10, color: colors.Secondary, borderColor: colors.Secondary, borderRightWidth: 1 }}>Net Rate</Text>
                                            <Text style={{ fontSize: 10, fontWeight: '900', width: '12%', textAlign: 'center', paddingTop: 10, paddingBottom: 10, color: colors.Secondary, borderColor: colors.Secondary }}>Amt</Text>
                                        </View>
                                        {/* TABLE CONTENT */}
                                        {
                                            res.list && res.list?.length > 0 && <FlatList
                                                data={res.list}
                                                renderItem={({ item, index }) => <Pressable style={{ flexDirection: 'row', width: '100%' }} key={new Date()}>
                                                    <Text style={{ fontSize: 12, fontWeight: res.list?.length - 1 ? 'bold' : '600', textAlign: 'center', width: '18%', paddingTop: 5, paddingBottom: 5, borderColor: colors.Secondary, borderBottomWidth: 1, color: colors.Secondary, borderLeftWidth: 1, borderRightWidth: 1 }}>{item.datatype != 'Total' && (item.created_at && moment(item.created_at).format('DD-MM-YY HH:mm'))}</Text>
                                                    <Text style={{ fontSize: 11, fontWeight: '600', textAlign: 'center', width: '10%', paddingTop: 5, paddingBottom: 5, color: colors.Secondary, borderColor: colors.Secondary, borderBottomWidth: 1, borderRightWidth: 1 }}>{item.datatype != 'Total' && (item?.type == 'NORMAL' ? 'NRM' : item?.type)}</Text>
                                                    <Text style={{ fontSize: 11, fontWeight: '600', textAlign: 'center', width: '10%', paddingTop: 5, paddingBottom: 0, color: colors.Secondary, borderColor: colors.Secondary, borderBottomWidth: 1, borderRightWidth: 1 }}>{item.datatype != 'Total' && item.lot.toFixed(2)}</Text>
                                                    <Text style={[styles.tableContent, { fontSize: index == res.list?.length - 1 ? 13 : 12, fontWeight: index == res.list?.length - 1 ? 'bold' : '600', textAlign: 'center', width: '9%', paddingTop: 5, paddingBottom: 0, color: item.order_action == 'BUY' ? '#2E2EFF' : colors.Secondary, borderColor: colors.Secondary, borderBottomWidth: 1, borderRightWidth: 1 }]}>{item.datatype != 'Total' ? (item.order_action == 'BUY' ? item.quantity : 0) : item.bq}</Text>
                                                    <Text style={[styles.tableContent, { width: '8%', color: item.order_action == 'SELL' ? 'red' : colors.Secondary, borderColor: colors.Secondary, fontSize: index == res.list?.length - 1 ? 13 : 12, fontWeight: index == res.list?.length - 1 ? 'bold' : '600', borderRightWidth: 1 }]}>{item.datatype != 'Total' ? (item.order_action == 'SELL' ? item.quantity : 0) : item.sq}</Text>
                                                    <Text style={[styles.tableContent, { width: '15%', color: colors.Secondary, borderColor: colors.Secondary, fontSize: index == res.list?.length - 1 ? 13 : 12, fontWeight: index == res.list?.length - 1 ? 'bold' : '600', borderRightWidth: 1 }]}>{item.price && item.price.toFixed(2)}</Text>
                                                    <Text style={[styles.tableContent, { width: '17.8%', color: colors.Secondary, borderColor: colors.Secondary, fontSize: index == res.list?.length - 1 ? 13 : 12, fontWeight: index == res.list?.length - 1 ? 'bold' : '600', borderRightWidth: 1 }]}>{item.net_price && item.net_price.toFixed(2)}</Text>
                                                    <Text style={[styles.tableContent, { width: '12.2%', color: item.datatype != 'Total' ? (item.order_action == 'SELL' ? 'red' : item.order_action == 'BUY' ? '#2E2EFF' : colors.Secondary) : item.netamount > 0 ? '#2E2EFF' : 'red', borderColor: colors.Secondary, borderRightWidth: 1, fontSize: index == res.list?.length - 1 ? 13 : 12, fontWeight: index == res.list?.length - 1 ? 'bold' : '600' }]}>{item.datatype != 'Total' ? (item.order_action == 'SELL' ? '-' + (item.net_price * item.quantity).toFixed(2) : (item.net_price * item.quantity).toFixed(2)) : item.netamount.toFixed(2)}</Text>
                                                </Pressable>}
                                                keyExtractor={item => item.id}
                                            />
                                        }
                                    </>
                                )
                            })
                        }
                    </View>

                </ScrollView>
                {
                    !isLoading && reportData?.length == 0 &&
                    <View style={{ justifyContent: 'center', alignItems: 'center', alignSelf: 'center', flex: 1, marginBottom: 100 }}>
                        <Text style={{ color: colors.Secondary }}>No Data Found!</Text>
                    </View>
                }
                {isLoading && reportData.length == 0 && <View style={styles.loaderView}>
                    <ActivityIndicator size="small" color='#000' />
                </View>}
            </View>
        </>
    )
}

export default LedgerDetail;

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    header: {
        paddingLeft: 25,
        marginTop: 10,
        borderBottomWidth: 1,
        flexDirection: 'row',
        paddingRight: 25,
        height: 40
    },
    titleView: {
        alignItems: 'center',
        width: '90%'
    },
    headerTitle: {
        fontSize: 16,
    },
    verticalLine: {
        borderRightWidth: 1
    },
    filterIcon: {
        height: 25,
        width: 25,
        marginTop: 3,
        marginRight: 10
    },
    headerIcon: {
        flexDirection: 'row'
    },
    dateInput: {
        borderRadius: 12,
        width: Width * 0.9,
        height: 45,
        paddingLeft: 15,
        bottom: 6,
        marginTop: 10,
        justifyContent: 'center'
    },
    label: {
        color: color1,
        fontSize: 15,
        fontFamily: 'Roboto-Regular',
        fontWeight: '600',
        marginTop: 10
    },
    cancelButton: {
        borderRadius: 5,
        marginTop: 18,
        marginLeft: 10,
        borderWidth: 1
    },
    cancelText: {
        fontSize: 16,
        textAlign: 'center',
        padding: 10,
        backgroundColor: 'transparent',
        marginLeft: 15,
        marginRight: 15
    },
    continueButton: {
        borderRadius: 5,
        marginTop: 18,
        marginLeft: 10,
    },
    continueText: {
        fontSize: 16,
        textAlign: 'center',
        color: 'white',
        padding: 10,
        backgroundColor: 'transparent',
        marginLeft: 15,
        marginRight: 15
    },
    segmentTabView: {
        flexDirection: 'row',
        marginLeft: 22,
        marginRight: 22,
        marginTop: 10
    },
    segementTab: {
        width: '50%',
        alignItems: 'center',
        borderWidth: 1,
        padding: 3,
        borderRadius: 5,
        marginLeft: 5
    },
    tableContent: {
        borderBottomWidth: 1,
        // padding: 2,
        textAlign: 'center',
        paddingTop: 5,
        paddingBottom: 0,
    },
    loaderView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 300
    },
})