import { StyleSheet, View, Text, Image, Pressable, TextInput } from 'react-native';
import { Primary, Secondary, color2, color1, Width } from '../../Utility/Utility';
import { useTheme } from '../../Contexts/ThemeProvider';
import backBlackIcon from '../../Assets/Images/back-arrow-black.png';
import { useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useState } from 'react';
import { resetAuth } from '../../Actions/AuthActions';
import { useDispatch } from 'react-redux';

const Margin = () => {
    const dispatch = useDispatch()
    const { dark, colors } = useTheme();
    const _onBack = () => props.navigation.goBack()
    const [marginData, setMarginData] = useState()

    useEffect(() => {
        console.log("here")
        getUserDetail()
    }, [])

    const getUserDetail = async () => {
        try {
            const jsonValue = await AsyncStorage.getItem('user_detail')
            const data = jsonValue != null ? JSON.parse(jsonValue) : null;
            console.log("id", data.id)
            getMargin(data.id)
        } catch (e) {
            // error reading value
        }
    }

    const getMargin = (id) => {
        axios.get(`${BASE_URL}/client-summary-report/${id}`)
            .then((res) => {
                console.log("res", res)
                setMarginData(res.data)
            })
            .catch((e) => {
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    return (
        <>
            <View style={[styles.container, { backgroundColor: colors.Primary }]}>
                <View style={[styles.header, { borderColor: colors.Secondary }]}>
                    <View>
                        <Pressable onPress={() => _onBack()}>
                            <Image source={dark ? backIcon : backBlackIcon} style={{ marginTop: 5, height: 18, width: 18 }} />
                        </Pressable>
                    </View>
                    <View style={styles.titleView}>
                        <Text style={[styles.headerTitle, { color: colors.Secondary }]}>Margin</Text>
                    </View>
                </View>
                <View>
                    <Text style={{ fontWeight: 'bold', marginTop: 20, textAlign: 'center', fontSize: 18 }}>NSE</Text>
                    <View style={{ width: '100%', flexDirection: 'row' }}>
                        <View style={{ width: '50%', padding: 10 }}>
                            <Text>Margin</Text>
                            <Text>Margin Used</Text>
                            <View style={{ borderTopWidth: 1 }} />
                            <Text>Margin Balance</Text>
                        </View>
                        <View style={{ width: '50%', padding: 10, justifyContent: 'flex-end' }}>
                            <Text>{marginData?.nse_margin}</Text>
                            <Text>{marginData?.total_nse_used_margin}</Text>
                            <View style={{ borderTopWidth: 1 }} />
                            <Text>{marginData?.nse_bal}</Text>
                        </View>
                    </View>
                </View>
                <View>
                    <Text style={{ fontWeight: 'bold', marginTop: 20, textAlign: 'center', fontSize: 18 }}>MCX</Text>
                    <View style={{ width: '100%', flexDirection: 'row' }}>
                        <View style={{ width: '50%', padding: 10 }}>
                            <Text>Margin</Text>
                            <Text>Margin Used</Text>
                            <View style={{ borderTopWidth: 1 }} />
                            <Text>Margin Balance</Text>
                        </View>
                        <View style={{ width: '50%', padding: 10, justifyContent: 'flex-end' }}>
                            <Text>{marginData?.mcx_margin}</Text>
                            <Text>{marginData?.total_mcx_used_margin}</Text>
                            <View style={{ borderTopWidth: 1 }} />
                            <Text>{marginData?.mcx_bal}</Text>
                        </View>
                    </View>
                </View>
            </View>
        </>
    )
}

export default Margin;

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    header: {
        paddingLeft: 25,
        marginTop: 10,
        borderBottomWidth: 1,
        flexDirection: 'row',
        paddingRight: 25,
        height: 40
    },
    headerTitle: {
        fontSize: 16,
    },
    titleView: {
        alignItems: 'center',
        width: '90%'
    },
})