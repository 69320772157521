import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Text, Image, Pressable, TextInput, FlatList, ActivityIndicator } from 'react-native';
import { Primary, Secondary, color2, color1, Width } from '../../Utility/Utility';
import backIcon from '../../Assets/Images/backArrow.png';
import backBlackIcon from '../../Assets/Images/back-arrow-black.png';
import { useTheme } from '../../Contexts/ThemeProvider';
import axios from 'axios';
import { BASE_URL } from '../../Config';
import moment from 'moment';
import { resetAuth } from '../../Actions/AuthActions';
import { useDispatch } from 'react-redux';

const Notification = (props) => {
    const dispatch = useDispatch()
    const { dark, colors } = useTheme();
    const _onBack = () => props.navigation.goBack()
    const [notifications, setNotifications] = useState([]);
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        getAllNotifications()
    }, [])

    const getAllNotifications = () => {
        setLoading(true)
        axios.get(`${BASE_URL}/message-list`)
            .then((res) => {
                setLoading(false)
                setNotifications(res.data)
            })
            .catch((e) => {
                setLoading(false)
                if (e.response.status == 401) {
                    dispatch(resetAuth())
                }
            })
    }

    return (
        <>
            <View style={[styles.container, { backgroundColor: colors.Primary }]}>
                <View style={[styles.header, { borderColor: colors.Secondary }]}>
                    <View>
                        <Pressable onPress={() => _onBack()}>
                            <Image source={dark ? backIcon : backBlackIcon} style={{ marginTop: 5, height: 18, width: 18 }} />
                        </Pressable>
                    </View>
                    <View style={styles.titleView}>
                        <Text style={[styles.headerTitle, { color: colors.Secondary }]}>Notifications</Text>
                    </View>
                </View>
                {
                    notifications.length > 0 && <FlatList
                        data={notifications}
                        renderItem={({ item }) => <View style={{ padding: 10, borderBottomWidth: 1, borderBottomColor: '#808080', backgroundColor: item.is_read == 0 ? '#add8e6' : 'white' }} key={new Date()}>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Text style={{ color: colors.Secondary, fontSize: 13 }}>{item.created_at && moment(item.created_at).format('DD/MM/YYYY HH:mm:ss')}</Text>
                            </View>
                            <Text style={{ fontWeight: '700', color: colors.Secondary, fontSize: 15 }}>{item.description}</Text>


                            {/* <Text style={{ color: '#000', fontWeight: '500' }}>{item.description}</Text> */}
                        </View>}
                        keyExtractor={item => item.id}
                    />
                }
                {isLoading && notifications.length == 0 && <View style={styles.loaderView}>
                    <ActivityIndicator size="small" color='#000' />
                </View>}
                {
                    !isLoading && notifications.length == 0 &&
                    <View style={styles.loaderView}>
                        <Text style={{ color: colors.P }}>No Quantity Setting Found!</Text>
                    </View>
                }
            </View>
        </>
    )
}

export default Notification;

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    header: {
        paddingLeft: 25,
        marginTop: 10,
        borderBottomWidth: 1,
        flexDirection: 'row',
        paddingRight: 25,
        height: 40
    },
    headerTitle: {
        fontSize: 16,
    },
    titleView: {
        alignItems: 'center',
        width: '90%'
    },
    loaderView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
})